import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Search, useRecentOpenings } from 'modules/talentOpenings';
import { Layout } from './components/Layout';
import { Header } from './components/Header';
import { OpeningBox } from 'modules/app/components/OpeningBox';
import { HttpError } from 'modules/app/http/components/HttpError';

import * as openingBox from './styles/OpeningBox.styles';
import * as styles from './styles/Root.styles';

import { Slider } from 'modules/app/components/Slider';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

export const ExploreOpenings: React.FC<RouteComponentProps> = () => {
  const { getRecentOpenings } = useRecentOpenings();
  const { recentOpenings, error } = useSelector(
    (state: AppState) => state.talentOpenings,
  );

  useEffect(() => {
    getRecentOpenings();
  }, []);

  return (
    <Layout>
      <Header isOpenings>
        {!error && recentOpenings?.length ? (
          <Slider>
            {recentOpenings?.map((opening, index) => (
              <OpeningBox
                key={index}
                opening={opening}
                theme="dark"
                customStyles={openingBox.opening}
              />
            ))}
          </Slider>
        ) : (
          <section css={styles.wrapper}>
            <HttpError error={error} />
          </section>
        )}
      </Header>
      <Search />
    </Layout>
  );
};
