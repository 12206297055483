import { CookieConsent, PublicLayout } from 'modules/app';
import Seo from 'modules/app/components/Seo';
import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';
import { ExploreOpenings } from 'modules/explore/ExploreOpenings';
import React from 'react';

const Openings: React.FC<WebPageProps> = ({ pageContext }) => {
  const { siteUrl } = useSiteMetadata();
  const { intl } = pageContext;
  const path = `${siteUrl}/${intl.language}${intl.originalPath}`;

  return (
    <>
      <Seo
        url={path}
        title="Browse Job Openings - Roango"
        description="Find job oppenings and discover opportunities that are perfectly aligned with your skills, company culture, and personal preferences."
        image={`${siteUrl}/roango-share-image.png`}
      />

      <PublicLayout>
        <ExploreOpenings />
      </PublicLayout>

      <CookieConsent />
    </>
  );
};

export default Openings;
